import React, { useState } from 'react';
import './Service.css';
import { tv, earth, magnet } from '../../assets';
import { Fade } from "react-awesome-reveal";


const Service = () => {
  const [hover, setHover] = useState(null);

  const handleHover = (serviceIndex) => {
    setHover(serviceIndex);
  };

  const handleLeave = () => {
    setHover(null);
  };

  return (
    <section id='services'>
      <Fade>
      <div className="wrapper">
        <div className="content-container">

          <div 
            className={`service ${hover === 0 ? 'hover' : ''}`}
            onMouseEnter={() => handleHover(0)}
            onMouseLeave={handleLeave}
          >
            <img
              style={{ filter: hover === 0 ? 'brightness(0)' : 'none' }}
              className='' src={tv} alt="" />
            <div
              style={{ background: hover === 0 ? '#00294B 0% 0% no-repeat padding-box' : '' , boxShadow: hover === 0 ? '0px 3px 6px #00000029' : '', border: hover === 0 ? 'none' : ''}}
              className="tv service-container">
              <div
                style={{ color: hover === 0 ? 'white' : '' }}
                className=" servicename">On-the-Go Accessibility</div>
              <div
                style={{ color: hover === 0 ? 'white' : '' }}
                className="description">Enjoy Young TV anytime, anywhere with our online streaming services,making entertainment accessible on multiple devices.</div>
            </div>
          </div>

          <div 
            className={`service ${hover === 1 ? 'hover' : ''}`}
            onMouseEnter={() => handleHover(1)}
            onMouseLeave={handleLeave}
          >
            <img 
              src={earth} 
              alt="" 
              style={{ filter: hover === 1 ? 'brightness(0)' : 'none' }}
            />
            <div 
              className="service-container"
              style={{ background: hover === 1 ? '#00294B 0% 0% no-repeat padding-box' : '' , boxShadow: hover === 1 ? '0px 3px 6px #00000029' : '', border: hover === 1 ? 'none' : ''}}
            >
              <div
                style={{ color: hover === 1 ? 'white' : '' }}
                className="servicename">Global Perspectives</div>
              <div
                style={{ color: hover === 1 ? 'white' : '' }}
                className="description">
                Experience the world from your screen with content that transcends borders, offering diverse stories and insights.
              </div>
            </div>
          </div>

          <div 
            className={`service ${hover === 2 ? 'hover' : ''}`}
            onMouseEnter={() => handleHover(2)}
            onMouseLeave={handleLeave}
          >
            <img 
              style={{ filter: hover === 2 ? 'brightness(0)' : 'none' }}
              src={magnet} alt="" />
            <div
              style={{ background: hover === 2 ? '#00294B 0% 0% no-repeat padding-box' : '' , boxShadow: hover === 2 ? '0px 3px 6px #00000029' : '', border: hover === 2 ? 'none' : ''}}
              className="service-container">
              <div
                style={{ color: hover === 2 ? 'white' : '' }}
                className="servicename">Interactive Engagement</div>
              <div
                style={{ color: hover === 2 ? 'white' : '' }}
                className="description">Engage with the Young TV community through real-time polls, contests, and giveaways, making your viewing experience interactive and enjoyable.</div>
            </div>
          </div>

        </div>
      </div>
      </Fade>
    </section>
  );
};

export default Service;
