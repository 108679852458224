import React from 'react'
import './Partners.css'
import {dish,freesat, digitalnext, logo} from '../../assets'
import { Fade } from "react-awesome-reveal";


const Partners = () => {
  return (
    <section id='partners'>
      <Fade>
      <div className="wrapper">
        <h2 className='partner'>Our Partners</h2>
        <div className="para">
        <p>At Young TV, we are proud to collaborate with esteemed partners who share our vision of providing diverse and engaging content for every generation. These partnerships enable us to enrich our programming, expand our reach, and create meaningful connections with our audience.</p>
        </div>
        
    <div className="content-container">
        <div className="partner">
            <img src={freesat} alt="" />
        </div>
        <div className="partner">
            <img src={dish} alt="" />
        </div>
        <div className="partner">
            <img src={digitalnext} alt="" />
        </div>
    </div>
      </div>
      </Fade>
    </section>
  )
}

export default Partners