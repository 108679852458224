import React from 'react';
import './Header.css';
import NavBar from '../NavBar/NavBar';
import Nav from '../Nav/Nav';
import { Fade } from "react-awesome-reveal";

const Header = () => {
  return (

    <header >
      <div className="head">
    
    <div className="wrapper">
    <div className="cta-container">
      <div className="cta">
      <div className='title' >Empowering Youth Through Entertainment</div>
      <p>Young TV, a proud creation of Young Asia Media Corporation (PVT) LTD, is not just a<br/> television channel; we are a movement, a celebration of the dynamic energy that<br/> defines today's youth. </p>
      <a href="#contactus"><button className='get-btn'>Contact Now</button></a>
      </div>
      </div>
    </div>
    </div>
    </header>
  );
};

export default Header;
